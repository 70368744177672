import React, { useEffect } from "react";
import "./menu.scss";
import { useHistory, Link } from "react-router-dom";
// import { UpperFirst } from '../../helpers/utils'
import CONTENT from "../../content.json";

const Menu = ({ closeMenu, open }) => {
  const history = useHistory();
  useEffect(() => history.listen(() => closeMenu?.()));
  return (
    <nav className={"menu" + (open ? " open" : "")}>
      <ul className="pages">
        {/* <Link className="grand-titre" to="/poterie" onClick={closeMenu}>POTERIE</Link> */}
        <Link
          className="grand-titre"
          style={{ lineHeight: "90%", marginBottom: 24 }}
          to="/?jeunesse"
          onClick={closeMenu}
        >
          Littérature
          <br />
          de jeunesse
        </Link>
        <Link
          className="grand-titre"
          style={{ lineHeight: "90%", marginBottom: 24 }}
          to="/?roman"
          onClick={closeMenu}
        >
          Roman
        </Link>
        <Link className="grand-titre" to="/salons" onClick={closeMenu}>
          SALONS
        </Link>
        <Link className="grand-titre" to="/a-propos" onClick={closeMenu}>
          A PROPOS
        </Link>
      </ul>
      <span className="paragraphe-low bottom">
        {CONTENT.footer.legals} -{" "}
        <a href="/mentions-legales" target="_blank" style={{ color: "white" }}>
          Mentions Légales
        </a>
      </span>
    </nav>
  );
};

export default Menu;
